import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';

// components
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import CardTypeOne from '../components/CardTypeOne';
import CardTypeThree from '../components/CardTypeThree';
import Accordion from '../components/Accordion';
import Slider from '../components/Slider';
// import BannerCTA from '../components/BannerCTA';

// assets
import PristeemLogo from '../images/startups/pristeem.png';
import FlexnestLogo from '../images/startups/flexnest.png';
import DiallLogo from '../images/startups/diall.png';
import FanclubLogo from '../images/startups/fanclub.png';
import MojaLogo from '../images/startups/moja.png';
import KaviraLogo from '../images/startups/kavira.png';
import DebbieLogo from '../images/startups/debbie.png';
import Pelt8Logo from '../images/startups/pelt8.png';

// react icons
import { SiGraphql } from 'react-icons/si';
import { FaUserTie, FaLaptopHouse } from 'react-icons/fa';
import {
  MdInsertChart,
  MdDesignServices,
  MdSupportAgent,
} from 'react-icons/md';

const sliderOptions = {
  dots: true,
  arrows: true,
  autoPlay: false,
  draggable: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  swipeToSlide: true,
  swipe: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: true,
        centerMode: true,
        centerPadding: '0',
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: true,
        centerMode: true,
        centerPadding: '0',
      },
    },
  ],
};

const faqs = [
  {
    title: 'How much time does a POC and an MVP require?',
    description:
      'Taking our experiences into consideration, a POC usually takes 4weeks of time while an MVP requires 10 weeks of timeline.',
  },
  {
    title: 'What does our pricing look like?',
    description:
      'We follow a dynamic pricing structure and it keeps changing from time to time. Typically our pricing falls in between $25 to $45 per hour.',
  },
  {
    title: 'What kind of project do we do at HQ?',
    description:
      'We are specialised in Fintech, Healthcare, Social & Community Applications and cutting edge technologies such as IoT, BLE, AI, ML, Blockchain, NFT projects. Over 90% of our projects are built from scratch.',
  },
  {
    title: 'What all geographies have we built products for?',
    description:
      'Over 80% of our projects belong to the US. We have experience building products for countries such as the UK, Australia,India, European as well as African countries.',
  },
  {
    title: 'What are the essentials while building an app?',
    description:
      'While building an app for iOS, Android, or both, we also need to build a) Backend b) Web admin portal c) Integrate 3rd party APIs d) DevOps and Security e) Compliances, if any.',
  },
  {
    title: 'What technologies do we prefer for custom web applications?',
    description:
      'Node and Python are the most preferred ones for Backend Development while React, Angular, HTML/CSS are preferred for Frontend Development.',
  },
  {
    title: 'What technologies do we prefer for custom mobile applications?',
    description:
      'For Native iOS, we use Swift/Objective-C and for Native Android we use Java/Kotlin. Technologies such as React Native and Flutter are used for Hybrid Mobile Applications.',
  },
  {
    title: 'Cloud services - which one do we prefer?',
    description:
      'AWS is the most preferred one. Google GCP and Microsoft Azure are also used taking clients preference into consideration.',
  },
  {
    title: 'What tools do we use for UX, UI and Graphic designing?',
    description:
      'Figma, Miro, Principle, Adobe Suite are preferred by our creative team.',
  },
  {
    title: 'How long our engagements typically last?',
    description:
      'Most of our project engagements last from 6 months to 1 year. However, we have engagements with minimum commitment of over 2 years as well.',
  },
  {
    title: 'How much equity do we charge under the Cash & Equity Model?',
    description:
      'Equity payments depend on the type of engagement we are going to have. Some of the engagements are in percentage while some of the engagements are in number of shares.',
  },
  {
    title: 'What all time zones do we cover with our services?',
    description:
      'Most of us work as per the Eastern time zone (EST). However, business communications are available round the clock.',
  },
];

const startupSpecificServices = [
  {
    title: 'End-to-end Product Support',
    description:
      'We build experiences while building a product for you. We make sure to fulfill most of your product requests, even the smallest ones.',
  },
  {
    title: 'Quick POC and MVP',
    description:
      'Timing is crucial when working around a new product concept. Our team gets you there within a couple of weeks.',
  },
  {
    title: 'Build Better, Faster',
    description:
      'As an early stage startup, you need to build, scale and move faster. We help you achieve this with better tech, team and planning.',
  },
  {
    title: 'Your Team and Our Team',
    description:
      'Our digital work space is shared with you so you can interact, learn, improve and get familiar with our product development practices.',
  },
  {
    title: 'Flexibility with Scope Iterations',
    description:
      'We allow such flexibility so you can iterate your product and its features without any hassle.',
  },
  {
    title: 'Pay in Cash & Equity',
    description:
      'Your cash challenges are covered with the Cash & Equity model. You have the option to pay partial in cash and partial in equity. ',
  },
];

const thingsWeDoDifferently = [
  {
    title: 'Innovation',
    description:
      'We strive to bring a fresh perspective to your idea, features and modules so you can wow your customers with something new in the market.',
  },
  {
    title: 'Research Backed Recommendations',
    description:
      "Whether it's tech, design, or third party apis, our recommendations are based on research and our past experiences.",
  },
  {
    title: 'Risk Mitigation',
    description:
      'Our product experiences help you mitigate the risks early at the initial stage of product development. Our team suggests the possibilities which are ideal from the business perspective.',
  },
  {
    title: 'Pre and Post Product Support',
    description:
      'While in discussion, relevant insights around your product are shared to help you make the right decision. Also, once the product gets shipped, a few weeks or free support is extended to help you keep running.',
  },
];

const exclusiveSupport = [
  {
    icon: <SiGraphql size={90} />,
    title: 'Product Planning and Specifications',
  },
  {
    icon: <MdInsertChart size={90} />,
    title: 'Recommendations and Actionable Insights',
  },
  {
    icon: <MdDesignServices size={90} />,
    title: 'Creative Branding for Your Product',
  },
  {
    icon: <FaUserTie size={90} />,
    title: 'Acting CTO for Your Product',
  },
  {
    icon: <MdSupportAgent size={90} />,
    title: 'Extended Post Product Support',
  },
  {
    icon: <FaLaptopHouse size={90} />,
    title: 'In-house transition when ready to move',
  },
];

const startups = [
  { logo: PristeemLogo, name: 'Pristeem' },
  { logo: FlexnestLogo, name: 'Flexnest' },
  { logo: DiallLogo, name: 'Diall' },
  { logo: FanclubLogo, name: 'Fanclub' },
  { logo: MojaLogo, name: 'Moja' },
  { logo: KaviraLogo, name: 'Kavira' },
  { logo: DebbieLogo, name: 'Debbie' },
  { logo: Pelt8Logo, name: 'Pelt8' },
];

const Startup = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "startup-page-banner.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const imageData = data.desktop.childImageSharp.fluid;

  const sources = [
    { aspectRatio: 1, src: '', srcSet: '' },
    { ...imageData, media: '(min-width: 1024px)' },
  ];

  return (
    <Layout pageId="startup" location={location} footer={true}>
      <Seo title="Startup" />

      <Section id="hero" backgroundImage={sources}>
        <Container
          className="flex justify-start items-center pb-0 lg:pb-0 xl:pb-0"
          screenHeight
        >
          <div className="content-area">
            <div className="mb-0" style={{ maxWidth: 670 }}>
              <h1 className="fs-46 fs-lg-48 fw-500 mb-0 ff-tertiary">
                <span className="text-primary fs-50 fs-lg-52 fw-700">
                  Experience
                </span>{' '}
                the
              </h1>
              <h1 className="ff-tertiary fs-46 fs-lg-48 fw-500 mt-0 mb-0">
                Unexperienced!
              </h1>
            </div>
            <div
              className="fw-400 fs-14 fs-lg-16 mt-12 lg:mt-10"
              style={{ maxWidth: 493 }}
            >
              Services of HQ are dedicated to startups at different stages. You
              leverage our Expertise, Approach and CoBuild Ecosystem to mitigate
              the risks and build better products with our team.
            </div>
          </div>
        </Container>
      </Section>

      <Section id="startup-specific-services" backgroundColor="#FFF">
        <Container className="flex-col" screenHeight>
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              <span className="text-primary fw-400">Startup</span> Specific
              Services!
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {startupSpecificServices.map((data, index) => (
              <div key={index}>
                <CardTypeOne data={data} index={index + 1} />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="things-we-do-differently">
        <Container className="flex-col" screenHeight>
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48">
              Things we are doing{' '}
              <span className="text-primary fw-400">differently</span>
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {thingsWeDoDifferently.map((data, index) => (
              <div key={index}>
                <CardTypeOne data={data} theme="dark" />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="startup-faq" backgroundColor="#FFF">
        <Container className="flex-col" screenHeight>
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              <span className="text-primary fw-400">FAQs</span>
            </h2>
          </SectionHeading>
          <div className="w-full mt-16">
            {faqs.map((item, index) => {
              return (
                <div key={index} className="accordion">
                  <Accordion title={`Q. ${item.title}`} theme="light">
                    <p className="description">{item.description}</p>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </Container>
      </Section>

      <Section id="exclusive-support">
        <Container className="flex-col" screenHeight>
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48">
              <span className="text-primary fw-400">Exclusive Support</span> for
              Early Stage Startups
            </h2>
          </SectionHeading>
          <div className="hidden lg:block mx-auto">
            <div className="grid-container">
              {exclusiveSupport.map((data, index) => (
                <div key={index}>
                  <CardTypeThree data={data} index={index + 1} />
                </div>
              ))}
            </div>
          </div>
          <div className="slider-container w-full mt-28 block lg:hidden">
            <Slider
              id="slider__startup-support"
              settings={{ ...sliderOptions }}
            >
              {exclusiveSupport.map(({ icon, title }, index) => {
                return (
                  <span key={index}>
                    <div key={index} className="text-center">
                      <span className="icon inline-block">{icon}</span>
                      <h5 className="title fw-700 fs-24 mt-10">{title}</h5>
                    </div>
                  </span>
                );
              })}
            </Slider>
          </div>
        </Container>
      </Section>

      <Section id="startup-experience" backgroundColor="#FFF">
        <Container className="flex-col" screenHeight>
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              <span className="text-primary fw-400">Startups</span> we worked
              with
            </h2>
          </SectionHeading>
          <div className="flex-1 flex justify-center items-center">
            <div className="pre-startups">
              {startups.map((startup, index) => (
                <div key={index} className="flex justify-center items-center">
                  <img src={startup.logo} alt={startup.name} />
                </div>
              ))}
            </div>
          </div>
          {/* <BannerCTA
            title="About Us"
            navigateText="Navigate"
            navigateLink="/about-us"
          /> */}
        </Container>
      </Section>
    </Layout>
  );
};

Startup.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Startup;
