import React from 'react';
import PropTypes from 'prop-types';

const CardTypeThree = ({ data }) => {
  return (
    <div className="card-type-three">
      <div className="flex items-center">
        <span className="icon">{data.icon}</span>
      </div>
      <div className="title">{data.title}</div>
    </div>
  );
};

CardTypeThree.propTypes = {
  data: PropTypes.object,
};

CardTypeThree.defaultProps = {};

export default CardTypeThree;
