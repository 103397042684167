import React from 'react';
import PropTypes from 'prop-types';

const CardTypeOne = ({ data, index, theme }) => {
  return (
    <div className={`card-type-one ${theme}`}>
      <div className="flex items-center">
        {theme === 'dark' && <span className="tag" />}

        <span className="title">
          {index && (
            <span className="index-label">
              {index < 10 ? `0${index}` : index}
            </span>
          )}
          {data.title}
        </span>
      </div>
      <div className="description">{data.description}</div>
    </div>
  );
};

CardTypeOne.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  theme: PropTypes.string,
};

CardTypeOne.defaultProps = {
  theme: 'light',
};

export default CardTypeOne;
